import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ContentColumns = makeShortcode("ContentColumns");
const Card = makeShortcode("Card");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ContentColumns mdxType="ContentColumns">{[<Card key="ubytovanie" variant="vertical" image="izby/apartman-rodinny.jpg" title="Pohodlie v útulných izbách" to="/ubytovanie/" mdxType="Card">
		Izby a apartmány s nádhernými výhľadmi na priľahlý svah a prírodu.
	</Card>, <Card key="restauracia" variant="vertical" image="restauracia/13.jpg" title="Domáca kuchyňa" to="/restauracia/" mdxType="Card">
		Máme radi naše slovenské jedlá, pripravené doma v kuchyni a s láskou.
	</Card>, <Card key="okolie" variant="vertical" image="lyzovanie/IMG_0726-2.jpg" title="Lyžiarske Stredisko" to="/okolie/" mdxType="Card">
		Hotel sa nachádza len 50 metrov od lyžiarskeho svahu. Radi vám požičiame aj lyžiarsku výstroj.
	</Card>]}</ContentColumns>
    <h2>{`Aktuality`}</h2>
    <Box my={3} mdxType="Box"><Card variant="horizontal" image="lyzovanie/331755452_175887371862507_3072267418948735149_n-cut.jpg" imagePosition="50% 70%" title="Lyžiarska sezóna otvorená" to="https://vernar-studnicky.com/" mdxType="Card">
	S radosťou oznamujeme, že lyžiarska sezóna 2024/2025 je oficiálne otvorená!
	<br /><br />
	Aktuálne informácie o&nbsp;stave zjazdoviek, prevádzke vlekov a&nbsp;živé zábery z&nbsp;kamier na&nbsp;svahu nájdete na&nbsp;našej webovej stránke <b>vernar-studnicky.com</b>
      </Card></Box>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      